.footer{
    padding-top: 5%;
    padding-bottom: 5%;
    margin:auto;
    -webkit-background-size: cover;
    -moz-background-size: cover;
    -o-background-size: cover;
    background-size: cover;
    width: 100%;
    background :#282c34;


}
.section-header{
    padding-left: 10%;
}

.movparallax{
  background-image: url('./../../img/laura.JPEG');
  
  
  /* Set a specific height */
  width: 100%;
  min-height: 200px;
  /* Create the parallax scrolling effect */
  background-attachment: fixed;
  background-position: center center;
  background-repeat: no-repeat;
  background-size:contain;
}

.parallax2{
    /* The image used */
    background-image: url('./../../img/laura.JPEG');
  
  
   
 /* Turn off parallax scrolling for tablets and phones. Increase the pixels if needed */
@media only screen and (max-device-width: 800px) {

  background-image: url('https://imagedelivery.net/rXQkQjLMcsIgr9J-xeKCWA/bf75bba4-64e5-47bc-b3b1-ee68c9c45e00/public');


}
    
  }

  .parallax-text{
    padding-left: 30%;
    @media (max-width: 768px) {
      padding-left: 1%;
      margin: 5%;

    }

    text-align: justify;
    text-justify: inter-word;      color : white;
      font-size: 1.5rem;
      font-family: 'Minimal';
  }
  img {
    width: 200px;
  }
