.responsive-text{
    font-size: 2.5vh;
    @media screen and (max-width: 600px) {
        font-size: 2vw;
    }
    font-family: 'Minimal';



}

.styled-text    {
    text-transform: uppercase;
margin:1%;
font-weight: 900;
letter-spacing: 1px;
font-size: 3vh;
font-family: 'Syncopate', sans-serif;
color: white;
text-align: left;
}