.parallax3 {
    /* The image used */
    background-image: url("https://imagedelivery.net/rXQkQjLMcsIgr9J-xeKCWA/32333b6b-e4a0-4e6e-4a85-9c2367b4a300/Tissue");
  
    
    /* Set a specific height */
    width: 100%;
    min-height: 75  px;
    /* Create the parallax scrolling effect */
    background-attachment: fixed;
    background-position: center center;
    background-repeat: no-repeat;
    background-size:cover;
 /* Turn off parallax scrolling for tablets and phones. Increase the pixels if needed */
@media only screen and (max-device-width: 800px) {



}
  }


.mparallax3{
  background-image: url("https://imagedelivery.net/rXQkQjLMcsIgr9J-xeKCWA/32333b6b-e4a0-4e6e-4a85-9c2367b4a300/Tissue");
  
  
  /* Set a specific height */
  width: 100%;
  min-height: 600px;
  /* Create the parallax scrolling effect */
  background-position: center center;
  background-attachment: fixed;

  background-repeat: no-repeat;
  background-size:contain;
}
  